import {Stack, Caption, TextStyle} from "@shopify/polaris";
import React from "react";

const DataTuple = ({title, value, type}: any) => {


    return (
        <Stack spacing="loose">
            <Stack.Item fill>
                {/*<TextStyle>{title}</TextStyle>*/}
                <TextStyle variation="subdued">{title}</TextStyle>
            </Stack.Item>
            <Stack.Item>
                <Caption>{value ? value : ''}</Caption>
                {/*<TextStyle variation="subdued">{value}</TextStyle>*/}
            </Stack.Item>
        </Stack>
    )
}

export default DataTuple