import {useRef} from "react";

const useHeightAutoFit = () => {
    let ref: any = useRef(null)

    const setAutoFit = () => {
        ref.current && (ref.current.style.height = `calc(100vh - ${ref.current.offsetTop + 84}px)`)
    }

    return [ref, setAutoFit]
}

export default useHeightAutoFit