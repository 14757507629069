import React from "react";
import {useParams} from "react-router-dom";
import useSafeQueryHook from "../../../../hooks/safe-query/safe-query.hook";
import {useGetQuery} from "../../../../../services/erp-resource-api.service";
import {Card, Layout, Page} from "@shopify/polaris";
import DataTuple from "../../../../common/data-tuple/data-tuple.component";
import SoLinesList from "../components/so-lines-list.component";

const ViewSalesOrderPage = () => {

    const {name} = useParams()
    const {data, isFetching} = useSafeQueryHook(useGetQuery, {doc: 'SC Sales Order', name: name})

    const model = data ? data.data : {}

    const pageContent = (
        <Layout>
            <Layout.Section>
                <Card title="Sales order header" sectioned>
                    <Layout>
                        <Layout.Section>
                            <Layout>
                                <Layout.Section oneThird>
                                    <DataTuple title={'Ref PO Number'} value={model.doc_num}/>
                                </Layout.Section>
                                <Layout.Section oneThird>
                                    <DataTuple title={'Document Status'} value={model.status}/>
                                </Layout.Section>
                            </Layout>
                        </Layout.Section>
                    </Layout>
                </Card>

                <SoLinesList name={name}/>

            </Layout.Section>

            <Layout.Section secondary>
                <Card title="Document information" sectioned>
                    <Layout>
                        <Layout.Section>
                            <Layout>
                                <Layout.Section oneThird>
                                    <DataTuple title={'Status'} value={''}/>
                                </Layout.Section>
                            </Layout>
                        </Layout.Section>
                    </Layout>
                </Card>
            </Layout.Section>
        </Layout>
    )

    return (
        <Page
            fullWidth={true}
            title={'Sales Order'}
            subtitle={model ? model.name : ''}
            secondaryActions={[]}
            breadcrumbs={[{content: 'Products', url: '/app/sales-order'}]}
        >
            {pageContent}
        </Page>
    )
}

export default ViewSalesOrderPage