import React, {useCallback, useState} from "react";
import {Avatar, Card, EmptyState, Filters, ResourceItem, ResourceList, TextStyle} from "@shopify/polaris";
import AutoFitContainer from "../../../../common/auto-fit-container/auto-fit-container.component";
import PosCartActionsComponent from "../pos-cart-actions/pos-cart-actions.component";

const PosCartListComponent = () => {
    const [taggedWith, setTaggedWith] = useState('VIP');
    const [queryValue, setQueryValue] = useState('');

    const handleTaggedWithChange = useCallback(
        (value) => setTaggedWith(value),
        [],
    );
    const handleQueryValueChange = useCallback(
        (value) => setQueryValue(value),
        [],
    );
    const handleTaggedWithRemove = useCallback(() => setTaggedWith('VIP'), []);
    const handleQueryValueRemove = useCallback(() => setQueryValue('VIP'), []);
    const handleClearAll = useCallback(() => {
        handleTaggedWithRemove();
        handleQueryValueRemove();
    }, [handleQueryValueRemove, handleTaggedWithRemove]);

    const resourceName = {
        singular: 'cart item',
        plural: 'cart items',
    };

    const items: any = [
        {
            id: 112,
            url: 'customers/341',
            name: 'Mae Jemison',
            location: 'Decatur, USA',
            latestOrderUrl: 'orders/1456',
        },
        {
            id: 212,
            url: 'customers/256',
            name: 'Ellen Ochoa',
            location: 'Los Angeles, USA',
            latestOrderUrl: 'orders/1457',
        },
    ];

    const filters: any = [];

    const appliedFilters = [];

    const filterControl = (
        <Filters
            queryValue={queryValue}
            filters={filters}
            onQueryChange={handleQueryValueChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleClearAll}
        >

        </Filters>
    );

    const emptyStateMarkup =
        !appliedFilters.length && !items.length ? (
            <EmptyState
                heading="Upload a file to get started"
                action={{content: 'Upload files'}}
                image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png"
            >
                <p>

                </p>
            </EmptyState>
        ) : undefined;

    return (
        <Card title="Current Cart">
            <AutoFitContainer>
                <ResourceList
                    resourceName={resourceName}
                    items={items}
                    renderItem={renderItem}
                    filterControl={filterControl}
                />

                <Card.Section>
                    <PosCartActionsComponent/>
                </Card.Section>
            </AutoFitContainer>
        </Card>
    );

    function renderItem(item: any) {
        const {id, url, name, location} = item;
        const media = <Avatar customer size="medium" name={name}/>;
        return (
            <ResourceItem
                id={id}
                url={url}
                media={media}
                accessibilityLabel={`View details for ${name}`}
                // shortcutActions={shortcutActions}
                // persistActions
            >
                <h3>
                    <TextStyle variation="strong">{name}</TextStyle>
                </h3>
                <div>{location}</div>
            </ResourceItem>
        );
    }

    function disambiguateLabel(key: any, value: any) {
        switch (key) {
            case 'taggedWith3':
                return `Tagged with ${value}`;
            default:
                return value;
        }
    }

    function isEmpty(value: any) {
        if (Array.isArray(value)) {
            return value.length === 0;
        } else {
            return value === '' || value == null;
        }
    }
}

export default PosCartListComponent