import {Frame, TopBar} from '@shopify/polaris'
import {Outlet} from "react-router-dom";
import React, {useCallback, useState} from "react";

const InternalPosLayout = () => {

    const [userMenuActive, setUserMenuActive] = useState(false);

    const toggleUserMenuActive = useCallback(
        () => setUserMenuActive((userMenuActive) => !userMenuActive),
        [],
    );

    const userMenuActions = [
        {
            items: [{content: 'Go to Admin Panel'}],
        },
    ];

    const userMenuMarkup = (
        <TopBar.UserMenu
            actions={userMenuActions}
            name="Dinesh Liyanage"
            detail={'Deligan SE'}
            initials="D"
            open={userMenuActive}
            onToggle={toggleUserMenuActive}
        />
    );

    const topBarMarkup = (
        <TopBar
            showNavigationToggle
            userMenu={userMenuMarkup}
        />
    );

    return (
        <Frame topBar={topBarMarkup}>
            <Outlet></Outlet>
        </Frame>
    )
}

export default InternalPosLayout;