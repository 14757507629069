import { Button, Card, DisplayText, FormLayout, Heading, Stack, TextField } from "@shopify/polaris";
import { useCallback, useReducer } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "../../../services/user.service";
import * as ToastActions from '../../../store/reducers/toast.store';
import { AppDispatch } from '../../../store/store';
import Logo from './logo.svg'

const LoginPage = () => {

    const [logIn, {data, isLoading}] = useLoginMutation();
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate()

    const formGroup = {
        usr: {key: 'usr', label: 'Username', type: 'text'},
        pwd: {key: 'pwd', label: 'Password', type: 'password'}
    }

    const [form, setForm] = useReducer(
        (state: any, value: any) => ({...state, ...value}),
        {}
    );

    const handleSubmit = async () => {
        try {
            let message = await logIn(form).unwrap();
            navigate(`/app/sales-order`)
        } catch (e: any) {
            dispatch(ToastActions.showToast({content: e.data.message}))
        }
    };

    const handleInputChange = useCallback(
        (value, id) => {
            setForm({[id]: value});
        },
        []
    );
    return (
        <div style={{width: "600px",}}>
            <Card sectioned>
                <div style={{height: '70vh', display:'flex', flexFlow: 'column', justifyContent: 'space-around', padding: '3rem'}}>
                    <FormLayout>
                        <img src={Logo}/>
                        <Heading>Supplier Connect</Heading>
                        <TextField
                            value={form[formGroup.usr.key]}
                            onChange={handleInputChange}
                            id={formGroup.usr.key}
                            label={formGroup.usr.label}
                            type={'text'}
                            autoComplete=""
                        />

                        <TextField
                            value={form[formGroup.pwd.key]}
                            onChange={handleInputChange}
                            id={formGroup.pwd.key}
                            label={formGroup.pwd.label}
                            type={'password'}
                            autoComplete=""
                        />

                        <Button onClick={handleSubmit}>Log In</Button>
                    </FormLayout>

                </div>
            </Card>
        </div>
    );
};

export default LoginPage;
