import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import useSafeQueryHook from "../../../../hooks/safe-query/safe-query.hook";
import {useGetQuery, useUpdateMutation} from "../../../../../services/erp-resource-api.service";
import React, {useCallback, useEffect, useReducer, useRef, useState} from "react";
import {showToast} from "../../../../../store/reducers/toast.store";
import QRCode from "qrcode";
import {Card, DisplayText, FormLayout, Layout, Page, TextField} from "@shopify/polaris";
import DataTuple from "../../../../common/data-tuple/data-tuple.component";
import SoViewLineListComponent from "../components/so-view-line-list.component";
import BatchSelectionAutoComplete from "../components/batch-selection-auto-complete.component";
import ViewQrCode from "../view-line/components/view-qr-code";
import PalletPlanLineListComponent from "./pallet-plan-line-list.component";

const ViewPalletPlanPage = () => {

    const dispatch = useDispatch()
    const {name} = useParams()
    const {data, isFetching} = useSafeQueryHook(useGetQuery, {doc: 'SC Pallet Plan', name: name})
    const [qrCode, setQRCode] = useState('')
    const [saveModel] = useUpdateMutation()
    const [form, setForm] = useReducer(
        (state: any, value: any) => ({...state, ...value}),
        {}
    );

    const save = () => {
        try {
            saveModel({method: `SC Pallet Plan/${name}`, data: data}).unwrap()
            dispatch(showToast({content: 'Saved successfully!'}))
        } catch (e) {
            dispatch(showToast({content: 'Failed to save'}))
            console.log(e)
        }
    }

    const generateQR = async (text: any) => {
        try {
            let url = await QRCode.toDataURL(text)
            setQRCode(url)
        } catch (err) {
            console.error(err)
        }
    }

    const model = data ? data.data : {}

    if(model) {
        generateQR(model.name)
    }

    const pageContent = (
        <Layout>
            <Layout.Section>
                <Card title="General Information" sectioned>
                    <Layout>
                        <Layout.Section>
                            <Layout>
                                <Layout.Section oneThird>
                                    <DataTuple title={'Line Number'} value={model.idx + 1}/>
                                    <DataTuple title={'Pallet Code'} value={model.name}/>
                                    <DataTuple title={'Pallet Type'} value={model.type}/>
                                </Layout.Section>
                                <Layout.Section oneThird>
                                </Layout.Section>
                            </Layout>
                        </Layout.Section>
                    </Layout>
                </Card>
                {data ? <PalletPlanLineListComponent model={model} lines={model.lines} onChangeValue={(line: any) => {
                    // lines.current.batches.push(line)
                }}/> : null}
            </Layout.Section>

            <Layout.Section secondary>

                {/*<Card title={'QR Code Generation'} sectioned actions={[{*/}
                {/*    content: "Generate", onAction() {*/}
                {/*        let model = data.data*/}
                {/*        generateQR(`${model.doc_entry}$${model.line_number}$${model.item_code}$${form.batch_number}$${model.sc_sales_order}$${model.name}$$${form.mc_number}`)*/}
                {/*        // generateQRToCanves(`${model.doc_entry}$${model.line_number}$${model.item_code}$${form.batch_number}$${model.sc_sales_order}$${model.name}$$${form.mc_number}`)*/}
                {/*    }*/}
                {/*}]}>*/}
                {/*</Card>*/}

                <ViewQrCode qrCode={qrCode}></ViewQrCode>


            </Layout.Section>
        </Layout>
    )

    return (
        <Page
            fullWidth={true}
            primaryAction={{
                content: 'Save', onAction() {
                    save()
                }
            }}
            title={'Pallet Plan'}
            subtitle={`${model.sc_sales_order} - ${model.name} - ${model.type}`}
            breadcrumbs={[{content: 'Products', url: `/app/sales-order/${model.sc_sales_order}`}]}
        >
            {pageContent}
        </Page>
    )
}

export default ViewPalletPlanPage