import {Frame} from '@shopify/polaris'

const RootLayout = ({children}: any) => {

    return (
        <Frame>
            {children}
        </Frame>
    )
}

export default RootLayout;