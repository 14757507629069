import useSafeQueryHook from "../../../../../hooks/safe-query/safe-query.hook";
import {useGetQuery} from "../../../../../../services/erp-resource-api.service";
import {useCallback, useState} from "react";
import {useNavigate} from "react-router-dom";
import {EmptySearchResult, Filters, IndexTable, Scrollable, TextStyle, useIndexResourceState} from "@shopify/polaris";

const SalesOrderLineTable = ({name}: any) => {

    const [queryValue, setQueryValue] = useState('');
    const handleQueryValueRemove = useCallback(() => setQueryValue('null'), []);
    const handleClearAll = useCallback(() => {
        handleQueryValueRemove();
    }, [handleQueryValueRemove]);
    const navigate = useNavigate()

    const filters: any[] = [];

    const {data, isFetching} = useSafeQueryHook(useGetQuery, {
        doc: 'SC Sales Order Line',
        name: `?fields=["*"]&filters=[["SC Sales Order Line", "sc_sales_order", "=", "${name}"]]&limit_page_length=10000`
    })

    const resourceName = {
        singular: 'PO line',
        plural: 'PO Lines',
    };

    const tabs = [
        {
            id: '1',
            content: 'Document Lines'
        },
        {
            id: '2',
            content: 'Pallet plan'
        },
    ];

    // panelID: 'repeat-customers-content-1',

    const handleOnClickIndexTableLine = (selectionType: any, toggleType: any, selection: any) => {
        try {
            if (selectionType == 'single') {
                navigate(`/app/sales-order-line/${selection}`)
            }

            handleSelectionChange(selectionType, toggleType, selection);
        } catch (ex) {
            console.log(ex)
        }
    }

    const {selectedResources, allResourcesSelected, handleSelectionChange} =
        useIndexResourceState(data ? data.data : []);

    const rowMarkup = data && data.data
        .filter((line: any) =>
            line.item_description.toLowerCase().includes(queryValue.toLowerCase()) ||
            line.item_code.toLowerCase().includes(queryValue.toLowerCase())
        )
        .map(
            (line: any, index: number) => (
                <IndexTable.Row
                    id={line.name}
                    key={index}
                    selected={selectedResources.includes(line.name)}
                    position={index}
                >
                    <IndexTable.Cell>
                        <TextStyle variation="strong">{line.item_code}</TextStyle>
                    </IndexTable.Cell>
                    <IndexTable.Cell>{line.item_description}</IndexTable.Cell>
                    <IndexTable.Cell>{line.quantity}</IndexTable.Cell>
                    <IndexTable.Cell>{line.price}</IndexTable.Cell>
                </IndexTable.Row>
            ),
        );

    const emptyStateMarkup = (
        <EmptySearchResult
            title={'No lines found'}
            description={'Try changing the filters or search term'}
            withIllustration
        />
    );

    return (
        <Scrollable shadow style={{height: '420px'}}>


            <div style={{padding: '16px', display: 'flex'}}>
                <div style={{flex: 1}}>
                    <Filters
                        queryValue={queryValue}
                        filters={filters}
                        onQueryChange={setQueryValue}
                        onQueryClear={handleQueryValueRemove}
                        onClearAll={handleClearAll}
                    />
                </div>
            </div>
            <IndexTable
                resourceName={resourceName}
                itemCount={data ? data.data.length : 0}
                selectedItemsCount={
                    allResourcesSelected ? 'All' : selectedResources.length
                }
                loading={isFetching}
                onSelectionChange={handleOnClickIndexTableLine}
                emptyState={emptyStateMarkup}
                headings={[
                    {title: 'Item Code'},
                    {title: 'Description'},
                    {title: 'Quantity'},
                    {title: 'Rate'}
                ]}
            >
                {rowMarkup}
            </IndexTable>
        </Scrollable>
    )
}

export default SalesOrderLineTable