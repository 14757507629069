import {Card} from "@shopify/polaris";
import PropTypes from 'prop-types';
import PosItemsDataTableComponent from "./components/pos-items-data-table/pos-items-data-table.component";
import PosItemsIndexTableComponent from "./components/pos-items-index-table/pos-items-index-table.component";

const PosCartTableComponent = ({type}: any) => {

    let tableMarkup;

    if(type == 'data-table') {
        tableMarkup = (<PosItemsDataTableComponent></PosItemsDataTableComponent>)
    } else if (type == 'index-table') {
        tableMarkup = (<PosItemsIndexTableComponent></PosItemsIndexTableComponent>)
    }

    return (
        <Card>
            {tableMarkup}
        </Card>
    )
}

PosCartTableComponent.prototypes = {
    type: PropTypes.string
};

export default PosCartTableComponent