import safeQueryHook from "../../hooks/safe-query/safe-query.hook";
import {useGetQuery} from "../../../services/erp-resource-api.service";
import {useExecReportQuery} from "../../../services/erp-method-api.service";
import QueryIndexTable from "./components/query-index-table.componet";
import ReportIndexTableComponent from "./components/report-index.table.component";
import {Spinner, Stack} from '@shopify/polaris'

const ListIndexTableComponent = ({query, type = 'Q', navBase}: any) => {

    console.log(navBase)
    let markup = (<></>)

    if (type == 'Q') {
        markup = (<QueryIndexTableGenerator navBase={navBase} query={query}></QueryIndexTableGenerator>)
    }
    else if(type == 'R') {
        markup = (<ReportIndexTableGenerator navBase={navBase} repost={'RES'}></ReportIndexTableGenerator>)
    }

    return (
        <>{markup}</>
    )
}

const QueryIndexTableGenerator = ({query, navBase}: any) => {

    const {data, isFetching} = safeQueryHook(useGetQuery, {doc: 'DLAD C Table', name: query})

    let markup = data && <QueryIndexTable navBase={navBase} tableConfig={data.data}></QueryIndexTable>

    return (
        <>{markup}</>
    )
}

const ReportIndexTableGenerator = ({report, navBase}: any) => {

    const {data, isFetching} = safeQueryHook(useExecReportQuery, {reportName: 'api_sc_get_sc_order_list'})

    let markup = !isFetching ? (<ReportIndexTableComponent navBase={navBase} data={data}/>) : (
        <Stack alignment={"center"} distribution={"fill"}>
            <Spinner size={"small"}/>
        </Stack>
    )

    return (
        <>{markup}</>
    )
}

export default ListIndexTableComponent