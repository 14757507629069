import React from 'react';
import PosCartListComponent from "../pos-cart-list/pos-cart-list.componrnt";


const PosCartComponent = () => {

    return (
        <>
            <PosCartListComponent />
        </>
    )
}

export default PosCartComponent;