import {Card, Modal} from '@shopify/polaris'
import {useCallback, useState} from "react";
import PosItemsIndexTableComponent
    from "../pos-cart-table/components/pos-items-index-table/pos-items-index-table.component";


const ItemSelectorModel = () => {
    const [active, setActive] = useState(true);

    const handleChange = useCallback(() => setActive(!active), [active]);

    return (

        <Modal
            open={active}
            title="All products"
            onClose={handleChange}
        >

            <Card title="Tags" sectioned>
                <PosItemsIndexTableComponent></PosItemsIndexTableComponent>
            </Card>
            {/*{Array.from({length: 50}, (_, index) => (*/}
            {/*    <Modal.Section key={index}>*/}
            {/*        <TextContainer>*/}
            {/*            <p>*/}
            {/*                Item <a href="#">#{index}</a>*/}
            {/*            </p>*/}
            {/*        </TextContainer>*/}
            {/*    </Modal.Section>*/}
            {/*))}*/}
        </Modal>
    );
}

export default ItemSelectorModel