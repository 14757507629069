import {Card, FormLayout, TextField} from '@shopify/polaris'
import PosCustomerComboComponent from "./components/pos-customer-combobox/pos-customer-combo.component";

const PosCustomerComponent = () => {

    return (
        <Card sectioned title="Find or create a customer">

            <FormLayout>

                {/*<TextField*/}
                {/*    value={'asd'}*/}
                {/*    onChange={() => {*/}
                {/*    }}*/}
                {/*    label=""*/}
                {/*    type="email"*/}
                {/*    autoComplete="email"*/}

                {/*/>*/}

                <PosCustomerComboComponent/>
            </FormLayout>
        </Card>
    )
}

export default PosCustomerComponent