import {createSlice} from '@reduxjs/toolkit'

interface IModalState {
    items: any,
    query: string
}

const initialState = {
    items: [],
    query: ''
} as IModalState

const PosStore = createSlice({
    name: 'pos-store',
    initialState,
    reducers: {
        setItems(state, action: any) {
            state.items = action.payload.data;
        },
        setQuery(state, action: any) {
            state.query = action.payload;
        },
    },
})

export const {setItems, setQuery} = PosStore.actions
export default PosStore.reducer