import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import BASE_URL from '../environments/environment';
import {EXEC_SAP_QUERY, GET_CURRENT_USER} from "../consts/method";

interface IQueryArgs {
    url: String;
    report_name: String;
}

export const MethodApi = createApi({
    reducerPath: "erpMethodApi",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + "api/method/",
        credentials: "include",
    }),
    endpoints: (build) => ({
        execReport: build.query<any, any>({
            query: ({reportName}: any) => `frappe.desk.query_report.run?report_name=${reportName}`
        }),
        executeQuery: build.query<any, any>({
            query: (payload) => ({
                url: EXEC_SAP_QUERY,
                method: 'POST',
                body: payload.data,
            }),
        }),
        getCurrentUser: build.query<any, any>({
            query: () => ({
                url: GET_CURRENT_USER,
                method: 'POST'
            }),
        }),
    }),
});

export const {useExecReportQuery, useExecuteQueryQuery, useGetCurrentUserQuery} = MethodApi;
