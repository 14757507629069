import {Card, Stack} from "@shopify/polaris";
import React from "react";

// @ts-ignore
import * as qz from 'qz-tray';

const ViewQrCode = ({qrCode}: any) => {

    const print = () => {
        try {
            qz.websocket.connect().then(() => {
                return qz.printers.find();
            }).then((printers: any) => {
                console.log(printers);
                let config = qz.configs.create('XP-58C');
                return qz.print(config, [{
                    type: 'pixel',
                    format: 'html',
                    flavor: 'plain',
                    data: `
                        <div>
                            <label>Tea Drop MC QR</label>
                            <img src='${qrCode}'/>
                        </div>
                    `
                }]);

                // let data = [
                //     '\x1B' + '\x69' + '\x61' + '\x00' + '\x1B' + '\x40', // set printer to ESC/P mode and clear memory buffer
                //     '\x1B' + '\x69' + '\x4C' + '\x01', // set landscape mode
                //     '\x1B' + '\x55' + '\x02', '\x1B' + '\x33' + '\x0F', // set margin (02) and line feed (0F) values
                //     '\x1B' + '\x6B' + '\x0B' + '\x1B' + '\x58' + '\x00' + '\x3A' + '\x00', // set font and font size
                //     'Printed by ', // "Printed by "
                //     'QZ-Tray', // "QZ-Tray"
                //     qrCode,
                //     '\x0A' +'\x0A',// line feed 2 times
                //     '\x1B' + '\x69' + '\x74' + '\x30', // set to code39 barcode
                //     '\x72' + '\x31', // characters below barcode
                //     '\x65' + '\x30' + '\x68' + '\x65' + '\x00' + '\x77' +'\x34' + '\x7A' + '\x32', // parentheses y/n, height, width of barcode, 2:1 ratio wide to narrow bars
                //     '\x42' + '1234567890' + '\x5C', // begin barcode data, data, end barcode data
                //     '\x0A' + '\x0A', // line feed 2x
                //     '\x0C' // <--- Tells the printer to print
                // ];
                //
                // return qz.print(config, data)

            }).then(() => {
                return qz.websocket.disconnect();
            }).then(() => {
                // process.exit(0);
            }).catch((err: any) => {
                console.error(err);

            })
        } catch (ex) {

        }
    }

    return (
        <Card actions={[{
            content: 'Print', onAction() {
                print()
            },
            disabled: qrCode ? false : true
        }]} title={'QR Code'} sectioned>
            <Stack alignment="center">
                <img src={qrCode}/>
            </Stack>
        </Card>
    )
}

export default ViewQrCode