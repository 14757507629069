import {useNavigate} from "react-router-dom";
import {IndexTable, useIndexResourceState} from "@shopify/polaris";

const ReportIndexTableComponent = ({data, navBase}: any) => {

    const navigate = useNavigate()

    const resourceName = {
        singular: 'purchase order',
        plural: 'purchase orders',
    };

    const {
        selectedResources,
        allResourcesSelected,
        handleSelectionChange
    } = useIndexResourceState(data ? data.message : []);

    const handleOnClickIndexTableLine = (selectionType: any, toggleType: any, selection: any) => {
        try {
            if (selectionType == 'single') {
                navigate(`/app/${navBase}/${selection}`)
            }

            handleSelectionChange(selectionType, toggleType, selection);
        } catch (ex) {
            console.log(ex)
        }
    }

    const header = data.message ? data.message.columns.map((_line:any) => ({title: _line.label})) : []
    console.log(data)

    const rowMarkup = data && data.message.result.map(
        (line: any, index: any) => (
            <IndexTable.Row
                id={line.name}
                key={index}
                selected={selectedResources.includes(line.name)}
                position={index}
            >
                {data.message.columns.map((_line: any) => (
                    <IndexTable.Cell key={`${index}-${_line.fieldname}`}>{line[_line.fieldname]}</IndexTable.Cell>))}
            </IndexTable.Row>
        ),
    );

    // const headers = tableConfig && tableConfig.header_fields.map((line: any, index: number) => <h1 key={index}>{line}</h1>)

    return (
        <>
            <IndexTable
                resourceName={resourceName}
                itemCount={data.message ? data.message.result.length : 0}
                selectedItemsCount={
                    allResourcesSelected ? 'All' : selectedResources.length
                }
                onSelectionChange={handleOnClickIndexTableLine}
                // loading={isFetching}
                selectable={true}
                headings={header}
            >
                {rowMarkup}

            </IndexTable>
        </>
    );
}

export default ReportIndexTableComponent