import React, {useCallback, useState} from "react";
import ListIndexTableComponent from "../../../../common/list-index-table/list-index-table.component";
import {Card, Page, Tabs} from "@shopify/polaris";

const ListSalesOrderPage = () => {

    const pageTitle = 'Sales Orders'

    const [selected, setSelected] = useState(0);

    const handleTabChange = useCallback(
        (selectedTabIndex) => setSelected(selectedTabIndex),
        [],
    );

    const tabs = [
        {
            id: '1f1f718f00',
            content: 'All',
            accessibilityLabel: 'All customers',
            panelID: 'all-customers-content-1',
        },
        {
            id: 'accepts-marketing-1',
            content: 'Drafts',
            panelID: 'accepts-marketing-content-1',
        },
        {
            id: 'repeat-customers-1',
            content: 'Confirmed',
            panelID: 'repeat-customers-content-1',
        },
        {
            id: 'prospects-1',
            content: 'Packed',
            panelID: 'prospects-content-1',
        },
        {
            id: 'prospects-2',
            content: 'Completed',
            panelID: 'prospects-content-1',
        },
    ];

    const pageContent = (
        <ListIndexTableComponent navBase={'sales-order'} type={'R'} query={tabs[selected].id}></ListIndexTableComponent>
    )

    return (
        <Page
            fullWidth={true}
            title={pageTitle}
            secondaryActions={[
                {
                    content: 'Export',
                    accessibilityLabel: 'Export',
                    onAction: () => alert('Export action'),
                }
            ]}
        >
            <Card>
                <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}></Tabs>
                {pageContent}
            </Card>
        </Page>
    )
}

export default ListSalesOrderPage