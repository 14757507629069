import {useRoutes} from "react-router-dom";
import ExternalPageContainer from "../components/layouts/external-page-container/external-page.container";
import LoginPage from "../components/pages/login/login.page";
import InternalMainLayout from "../components/layouts/internal/internal-main-layout/internal-main.layout";
import InternalPosLayout from "../components/layouts/internal/pos-layout/internal-pos.layout";
import PosHomePage from "../components/pages/pos/pos-home/pos-home.page";
import ViewPurchaseOrderPage
    from "../components/pages/docs/purchase-order/view/view-purchase-order.page";
import ListPurchaseOrderPage from "../components/pages/docs/purchase-order/list/list-purchase-order.page";
import ListSalesOrderPage from "../components/pages/docs/sales-order/list/list-sales-order.page";
import ViewSalesOrderPage from "../components/pages/docs/sales-order/view/view-sales-order.page";
import ViewSoLinePage from "../components/pages/docs/sales-order/view-line/view-so-line.page";
import ViewPalletPlanPage from "../components/pages/docs/sales-order/pallet-plan/view-pallet-plan.page";

const RouterConfigs = () => {
    const element = useRoutes([
        {
            path: "/",
            element: <ExternalPageContainer/>,
            children: [
                {
                    index: true,
                    element: <LoginPage/>
                }
            ]
        },
        {
            path: "/app",
            element: <InternalMainLayout/>,
            children: [
                {index: true, element: <h1></h1>},
                {
                    path: "/app/purchase-order",
                    element: <ListPurchaseOrderPage/>,
                },
                {
                    path: "/app/purchase-order/:name",
                    element: <ViewPurchaseOrderPage/>,
                },

                {
                    path: "/app/sales-order",
                    element: <ListSalesOrderPage/>,
                },
                {
                    path: "/app/sales-order/:name",
                    element: <ViewSalesOrderPage/>,
                },

                {
                    path: "/app/sales-order-line/:name",
                    element: <ViewSoLinePage/>,
                },
                {
                    path: "/app/pallet-plan/:name",
                    element: <ViewPalletPlanPage/>,
                }
            ],
        },
        {
            path: "/pos",
            element: <InternalPosLayout/>,
            children: [
                {index: true, element: <PosHomePage />},
            ],
        },
        {path: "*", element: <h1>page not found</h1>},
    ]);

    return element;
};

export default RouterConfigs;
