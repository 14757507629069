import {Card, Stack, DisplayText, FormLayout, Layout, Page, TextField} from "@shopify/polaris";
import React, {useCallback, useEffect, useReducer, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import useSafeQueryHook from "../../../../hooks/safe-query/safe-query.hook";
import {useGetQuery, useUpdateMutation} from "../../../../../services/erp-resource-api.service";
import DataTuple from "../../../../common/data-tuple/data-tuple.component";
import SoViewLineListComponent from "../components/so-view-line-list.component";
import {PrintMajor} from "@shopify/polaris-icons";
import BatchSelectionAutoComplete from "../components/batch-selection-auto-complete.component";
import QRCode from 'qrcode'
import {useDispatch} from "react-redux";
import {showToast} from "../../../../../store/reducers/toast.store";
import ViewQrCode from "./components/view-qr-code";

const ViewSoLinePage = () => {

    const dispatch = useDispatch()
    const {name} = useParams()
    const {data, isFetching} = useSafeQueryHook(useGetQuery, {doc: 'SC Sales Order Line', name: name})
    const [qrCode, setQRCode] = useState('')
    const [saveModel] = useUpdateMutation()
    const [form, setForm] = useReducer(
        (state: any, value: any) => ({...state, ...value}),
        {}
    );


    const lines: any = useRef({})

    if (data) {
        lines.current = JSON.parse(JSON.stringify(data.data))
    }

    useEffect(() => {
        console.log(lines.current.batches)
    }, [lines])

    const save = () => {
        dispatch(showToast({content: 'Failed to save'}))
        try {
            saveModel({method: `SC Sales Order Line/${name}`, data: {...lines.current}}).unwrap()
            dispatch(showToast({content: 'Saved successfully!'}))
        } catch (e) {
            dispatch(showToast({content: 'Failed to save'}))
            console.log(e)
        }
    }

    const handleInputChange = useCallback(
        (value, id) => {
            setForm({[id]: value});
        },
        []
    );

    const generateQR = async (text: any) => {
        try {
            let url = await QRCode.toDataURL(text)
            setQRCode(url)
        } catch (err) {
            console.error(err)
        }
    }

    // const generateQRToCanves = async (text: any) => {
    //     try {
    //         QRCode.toCanvas(document.getElementById('canvas'),
    //             'sample text', function (error) {
    //                 if (error) console.error(error)
    //                 console.log('success!')
    //             })
    //     } catch (err) {
    //         console.error(err)
    //     }
    // }

    const formGroup = {
        batch_number: {key: 'batch_number', label: 'Batch Number', type: 'text'},
        best_before: {key: 'best_before', label: 'Best Before', type: 'text'},
        quantity: {key: 'quantity', label: 'Quantity', type: 'text'},
    }

    const model = data ? data.data : {}

    const pageContent = (
        <Layout>
            <Layout.Section>
                <Card title="Sales order line info" sectioned>
                    <Layout>
                        <Layout.Section>
                            <Layout>
                                <Layout.Section oneThird>
                                    <DataTuple title={'Line Number'} value={model.idx + 1}/>
                                    <DataTuple title={'Item Code'} value={model.item_code}/>
                                    <DataTuple title={'Description'} value={model.item_description}/>
                                    <DataTuple title={'Quantity'} value={model.quantity}/>
                                    <DataTuple title={'Currency'} value={model.currency}/>
                                    <DataTuple title={'Sales Order'} value={model.sc_sales_order}/>
                                </Layout.Section>
                                <Layout.Section oneThird>
                                </Layout.Section>
                            </Layout>
                        </Layout.Section>
                    </Layout>
                </Card>
                {data ? <SoViewLineListComponent lines={data.data.batches} onChangeValue={(line: any) => {
                    lines.current.batches.push(line)
                }}/> : null}
            </Layout.Section>

            <Layout.Section secondary>

                <Card title={'QR Code Generation'} sectioned actions={[{
                    content: "Generate", onAction() {
                        let model = data.data
                        generateQR(`${model.doc_entry}$${model.line_number}$${model.item_code}$${form.batch_number}$${model.sc_sales_order}$${model.name}$$${form.mc_number}`)
                        // generateQRToCanves(`${model.doc_entry}$${model.line_number}$${model.item_code}$${form.batch_number}$${model.sc_sales_order}$${model.name}$$${form.mc_number}`)
                    }
                }]}>
                    <FormLayout>
                        {data ? <BatchSelectionAutoComplete onSelect={(value: any) => {
                            handleInputChange(value, 'batch_number')
                        }} data={data.data.batches}/> : null}
                        <TextField
                            value={form['mc_number']}
                            onChange={handleInputChange}
                            id={'mc_number'}
                            label={"MC Number"}
                            type={'number'}
                            autoComplete=""
                        />
                    </FormLayout>
                </Card>


                <ViewQrCode qrCode={qrCode}></ViewQrCode>

                <Card title={'Total Line Quantity'} sectioned>
                    <DisplayText size="extraLarge">{model.quantity}</DisplayText>
                </Card>

            </Layout.Section>
        </Layout>
    )

    return (
        <Page
            fullWidth={true}
            primaryAction={{
                content: 'Save', onAction() {
                    save()
                }
            }}
            title={'Sales Order Line'}
            subtitle={`${model.sc_sales_order} - ${model.name} - ${model.item_description}`}
            breadcrumbs={[{content: 'Products', url: `/app/sales-order/${model.sc_sales_order}`}]}
        >
            {pageContent}
        </Page>
    )
}

export default ViewSoLinePage;