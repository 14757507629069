import {AppProvider} from "@shopify/polaris";
import '@shopify/polaris/build/esm/styles.css';
import enTranslations from "@shopify/polaris/locales/en.json";
import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import RouterConfigs from "./routes/router-configs";
import {Provider} from "react-redux";
import {store} from "./store/store";

const theme = {
    logo: {
        width: 124,
        topBarSource: 'https://supplier-connect.vercel.app/static/media/logo.42a44147.svg',
        url: 'https://teadrop.com.au',
        accessibilityLabel: 'Tea Drop Australia'
    },
};

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <AppProvider theme={theme} i18n={enTranslations}>
                <BrowserRouter>
                    <RouterConfigs/>
                </BrowserRouter>
            </AppProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
