import {useRef} from "react";
import {useDispatch} from "react-redux";


const useSafeQuery = (query: any, method: any) => {

    const dispatch = useDispatch()
    const skip = useRef(false)

    const result = query(method, {skip: skip.current})

    if (result.isError) {
        skip.current = true
    } else {
        skip.current = false
    }

    return result
}

export default useSafeQuery