import React, {useState} from "react";
import {Card, EmptySearchResult, IndexTable, TextStyle, useIndexResourceState} from "@shopify/polaris";
import PopoverCellComponent from "../components/popover-cell.component";
import SoModelComponent from "../components/so-model.component";
import PalletPlanModalComponent from "./pallet-plan-modal.component";

const PalletPlanLineListComponent = ({model, lines, onChangeValue}: any) => {

    const [list, setList] = useState(lines)
    const [visible, setVisible] = useState(false)

    const resourceName = {
        singular: 'PO line',
        plural: 'PO Lines',
    };

    const handleOnClickIndexTableLine = (selectionType: any, toggleType: any, selection: any) => {
        try {
            if (selectionType == 'single') {
                console.log(selection)
            }

            handleSelectionChange(selectionType, toggleType, selection);
        } catch (ex) {
            console.log(ex)
        }
    }

    const {selectedResources, allResourcesSelected, handleSelectionChange} =
        useIndexResourceState(list);

    const rowMarkup = list && list.map(
        (line: any, index: number) => (
            <IndexTable.Row
                id={line.name}
                key={index}
                selected={selectedResources.includes(line.name)}
                position={index}
            >
                <IndexTable.Cell>
                    <TextStyle variation="strong">{line.item_code}</TextStyle>
                </IndexTable.Cell>
                <IndexTable.Cell>{line.description}</IndexTable.Cell>
                <IndexTable.Cell>{line.quantity}</IndexTable.Cell>
                <IndexTable.Cell><PopoverCellComponent/></IndexTable.Cell>
            </IndexTable.Row>
        ),
    );

    const emptyStateMarkup = (
        <EmptySearchResult
            title={'No lines found'}
            description={'Try changing the filters or search term'}
            withIllustration
        />
    );

    return (
        <Card title="Pallet Plan Lines" actions={[{
            content: 'Add Line', onAction() {
                setVisible(true)
            }
        }]}>
            <IndexTable
                resourceName={resourceName}
                itemCount={list ? list.length : 0}
                selectedItemsCount={
                    allResourcesSelected ? 'All' : selectedResources.length
                }
                selectable={false}
                onSelectionChange={handleOnClickIndexTableLine}
                emptyState={emptyStateMarkup}
                headings={[
                    {title: 'Item Code'},
                    {title: 'Description'},
                    {title: 'Quantity'},
                    {title: 'Actions'},
                ]}
            >
                {rowMarkup}
            </IndexTable>


            <PalletPlanModalComponent onSave={(form: any) => {
                setList([...list, form])
                onChangeValue(form)
            }}
                                      onModelCLose={() => {
                                          setVisible(false)
                                      }}
                                      model={model}
                                      visible={visible}
            />
        </Card>
    );
}

export default PalletPlanLineListComponent