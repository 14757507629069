import {Card, FormLayout, TextField} from '@shopify/polaris'

const PosNotesComponent = () => {
    return (
        <Card sectioned title="Notes">

            <FormLayout>

                <TextField
                    value={''}
                    onChange={() => {
                    }}
                    label=""
                    type="email"
                    autoComplete="email"

                />
            </FormLayout>
        </Card>
    )
}

export default PosNotesComponent