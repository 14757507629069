import {Card, Filters, IndexTable, TextField, useIndexResourceState} from "@shopify/polaris";
import {useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../../../store/store";
import {setItems, setQuery} from '../../../../../../../store/reducers/pos/pos.store';
import AutoFitContainer from "../../../../../../common/auto-fit-container/auto-fit-container.component";
import {useListQuery} from "../../../../../../../services/erp-resource-api.service";

const PosItemsIndexTableComponent = () => {

    const dispatch = useDispatch();

    const {items, query} = useSelector<RootState, any>((state) => state.posStore);

    const {data, isFetching} = useListQuery({
        fetchCount: 50,
        method: 'Item',
        fields: ['name', 'item_name'],
        filters: [['name', 'like', `${query}%`]]
    });

    if (data && data.data) {
        dispatch(setItems(data))
    }

    const setQueryValue = (value: any) => {
        dispatch(setQuery(value))
    }

    const resourceName = {
        singular: 'item',
        plural: 'items',
    };

    const {selectedResources, allResourcesSelected, handleSelectionChange} =
        useIndexResourceState(items);
    const [taggedWith, setTaggedWith] = useState('');

    const handleTaggedWithChange = useCallback(
        (value) => setTaggedWith(value),
        [],
    );
    const handleTaggedWithRemove = useCallback(() => setTaggedWith(''), []);
    const handleQueryValueRemove = useCallback(() => setQueryValue(''), []);
    const handleClearAll = useCallback(() => {
        handleTaggedWithRemove();
        handleQueryValueRemove();
    }, [handleQueryValueRemove, handleTaggedWithRemove]);

    const filters = [
        {
            key: 'taggedWith',
            label: 'Item Code',
            filter: (
                <TextField
                    label="Tagged with"
                    value={taggedWith}
                    onChange={handleTaggedWithChange}
                    autoComplete="off"
                    labelHidden
                />
            ),
            shortcut: true,
        },
    ];

    const appliedFilters = !isEmpty(taggedWith)
        ? [
            {
                key: 'taggedWith',
                label: disambiguateLabel('taggedWith', taggedWith),
                onRemove: handleTaggedWithRemove,
            },
        ]
        : [];

    const rowMarkup = items && items.map(
        ({name, item_name}: any, index: any) => (
            <IndexTable.Row
                id={name}
                key={name}
                position={index}
            >
                <IndexTable.Cell>{name}</IndexTable.Cell>
                <IndexTable.Cell>{item_name}</IndexTable.Cell>
                {/*<IndexTable.Cell>{location}</IndexTable.Cell>*/}
                {/*<IndexTable.Cell>{orders}</IndexTable.Cell>*/}
                {/*<IndexTable.Cell>{amountSpent}</IndexTable.Cell>*/}
            </IndexTable.Row>
        ),
    );

    return (
        <Card>
            <div style={{padding: '16px', display: 'flex'}}>
                <div style={{flex: 1}}>
                    <Filters
                        queryValue={query}
                        filters={filters}
                        appliedFilters={appliedFilters}
                        onQueryChange={setQueryValue}
                        onQueryClear={handleQueryValueRemove}
                        onClearAll={handleClearAll}
                    />
                </div>
            </div>

            <AutoFitContainer>
                <IndexTable
                    resourceName={resourceName}
                    itemCount={items.length}
                    onSelectionChange={handleSelectionChange}
                    hasMoreItems
                    loading={isFetching}
                    headings={[
                        {title: 'Name'},
                        {title: 'Item Name'},
                        // {title: 'Order count'},
                        // {title: 'Amount spent', hidden: false},
                    ]}
                >
                    {rowMarkup}
                </IndexTable>
            </AutoFitContainer>

        </Card>
    );

    function disambiguateLabel(key: any, value: any) {
        switch (key) {
            case 'taggedWith':
                return `Tagged with ${value}`;
            default:
                return value;
        }
    }

    function isEmpty(value: any) {
        if (Array.isArray(value)) {
            return value.length === 0;
        } else {
            return value === '' || value == null;
        }
    }
}

export default PosItemsIndexTableComponent