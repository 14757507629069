import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import BASE_URL from "../environments/environmet.prod";

export const ResourceApi = createApi({
    reducerPath: 'resourceApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + "api/resource/",
        credentials: 'include'
    }),
    tagTypes: ['Resource'],
    endpoints: build => ({
        list: build.query<any, any>({
            query: ({
                        method,
                        fetchCount = 15,
                        fields = [],
                        filters = []
                    }) => method + '?fields=' + JSON.stringify(fields) + '&' + 'filters=' + JSON.stringify(filters) + '&limit_page_length=' + fetchCount,
        }),
        get: build.query<any, any>({
            query: ({doc, name}) => `${doc}/${name}`,
            providesTags: ['Resource']
        }),
        create: build.mutation({
            query: (payload) => ({
                url: payload.method,
                method: 'POST',
                body: payload.data,
            }),
            invalidatesTags: ['Resource']
        }),
        update: build.mutation({
            query: (payload) => ({
                url: payload.method,
                method: 'PUT',
                body: payload.data,
            }),
        })
    })
})

export const {useListQuery, useGetQuery, useCreateMutation, useUpdateMutation} = ResourceApi