import {combineReducers} from "@reduxjs/toolkit";

import _toastStore from './toast.store';
import _posStore from './pos/pos.store';
import _uiStateStore from './ui-state.store';
import usersApi from "../../services/user.service";
import {ResourceApi} from "../../services/erp-resource-api.service";
import {MethodApi} from "../../services/erp-method-api.service";

const RootStore = combineReducers({
    [usersApi.reducerPath]: usersApi.reducer,
    [ResourceApi.reducerPath]: ResourceApi.reducer,
    [MethodApi.reducerPath]: MethodApi.reducer,

    toastStore: _toastStore,
    uiStateStore: _uiStateStore,
    posStore: _posStore
})

export default RootStore