import {DataTable} from "@shopify/polaris";

const PosItemsDataTableComponent = () => {

    const rows = [
        ['Emerald Silk Gown', '$875.00', 124689, '$122,500.00'],
        ['Mauve Cashmere Scarf', '$230.00', 124533, '$19,090.00'],
        ['Mauve Cashmere Scarf', '$230.00', 124533, '$19,090.00'],
        ['Mauve Cashmere Scarf', '$230.00', 124533, '$19,090.00'],
        ['Mauve Cashmere Scarf', '$230.00', 124533, '$19,090.00'],
        ['Mauve Cashmere Scarf', '$230.00', 124533, '$19,090.00'],
        ['Mauve Cashmere Scarf', '$230.00', 124533, '$19,090.00'],
        ['Mauve Cashmere Scarf', '$230.00', 124533, '$19,090.00'],
        ['Mauve Cashmere Scarf', '$230.00', 124533, '$19,090.00'],
        ['Mauve Cashmere Scarf', '$230.00', 124533, '$19,090.00'],
        ['Mauve Cashmere Scarf', '$230.00', 124533, '$19,090.00'],
        ['Mauve Cashmere Scarf', '$230.00', 124533, '$19,090.00'],
        ['Mauve Cashmere Scarf', '$230.00', 124533, '$19,090.00'],
        ['Mauve Cashmere Scarf', '$230.00', 124533, '$19,090.00'],
        ['Mauve Cashmere Scarf', '$230.00', 124533, '$19,090.00'],
        ['Mauve Cashmere Scarf', '$230.00', 124533, '$19,090.00'],
    ];


    return (
        <DataTable
            columnContentTypes={[
                'text',
                'numeric',
                'numeric',
                'numeric',
            ]}
            headings={[
                'Product',
                'Price',
                'Net quantity',
                'Net sales',
            ]}
            rows={rows}
            totals={['', '', 255, '$155,830.00']}
            showTotalsInFooter
        />
    )
}

export default PosItemsDataTableComponent