import {Autocomplete, Icon} from "@shopify/polaris";
import {useCallback, useState} from "react";
import {SearchMinor} from "@shopify/polaris-icons";

const BatchSelectionAutoComplete = ({data, onSelect}: any) => {
    const deselectedOptions = data.map((line: any, index: number) => ({key: index, value: line.batch_number, label: line.batch_number}))
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState(deselectedOptions);

    const updateText: any = useCallback(
        (value: any) => {
            setInputValue(value);

            if (value === '') {
                setOptions(deselectedOptions);
                return;
            }

            const filterRegex = new RegExp(value, 'i');
            const resultOptions = deselectedOptions.filter((option: any) =>
                option.label.match(filterRegex),
            );
            setOptions(resultOptions);
        },
        [deselectedOptions],
    );

    const updateSelection = useCallback(
        (selected) => {
            const selectedValue = selected.map((selectedItem: any) => {
                const matchedOption = options.find((option: any) => {
                    return option.value.match(selectedItem);
                });
                return matchedOption && matchedOption.label;
            });
            console.log(selected)
            onSelect(selected[0])
            setSelectedOptions(selected);
            setInputValue(selectedValue[0]);
        },
        [options],
    );

    const textField = (
        <Autocomplete.TextField
            autoComplete={'off'}
            onChange={updateText as any}
            label="Batch"
            value={inputValue}
            prefix={<Icon source={SearchMinor} color="base"/>}
            placeholder="Search"
        />
    );

    return (
        <>
            <Autocomplete
                options={options}
                selected={selectedOptions}
                onSelect={updateSelection}
                textField={textField}
            />
        </>
    );
}

export default BatchSelectionAutoComplete
