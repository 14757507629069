import {useCallback, useState} from "react";
import {
    Card,
    EmptySearchResult,
    Filters,
    IndexTable,
    TextStyle,
    useIndexResourceState,
    Tabs,
    Scrollable
} from "@shopify/polaris";
import {useGetQuery} from "../../../../../services/erp-resource-api.service";
import useSafeQueryHook from "../../../../hooks/safe-query/safe-query.hook";
import {useNavigate} from "react-router-dom";
import PalletPlanTable from "./pallet-plan-table/pallet-plan-table.component";
import SalesOrderLineTable from "./sales-order-line-table/sales-order-line-table.component";

const SoLinesList = ({name}: any) => {

    const {data, isFetching} = useSafeQueryHook(useGetQuery, {
        doc: 'SC Sales Order Line',
        name: `?fields=["*"]&filters=[["SC Sales Order Line", "sc_sales_order", "=", "${name}"]]&limit_page_length=10000`
    })

    const [queryValue, setQueryValue] = useState('');
    const handleQueryValueRemove = useCallback(() => setQueryValue('null'), []);
    const handleClearAll = useCallback(() => {
        handleQueryValueRemove();
    }, [handleQueryValueRemove]);
    const navigate = useNavigate()

    const filters: any[] = [];
    const resourceName = {
        singular: 'PO line',
        plural: 'PO Lines',
    };

    const tabs = [
        {
            id: '1',
            content: 'Document Lines'
        },
        {
            id: '2',
            content: 'Pallet plan'
        },
    ];

    // panelID: 'repeat-customers-content-1',

    const handleOnClickIndexTableLine = (selectionType: any, toggleType: any, selection: any) => {
        try {
            if (selectionType == 'single') {
                navigate(`/app/sales-order-line/${selection}`)
            }

            handleSelectionChange(selectionType, toggleType, selection);
        } catch (ex) {
            console.log(ex)
        }
    }

    const {selectedResources, allResourcesSelected, handleSelectionChange} =
        useIndexResourceState(data ? data.data : []);

    const rowMarkup = data && data.data.map(
        (line: any, index: number) => (
            <IndexTable.Row
                id={line.name}
                key={index}
                selected={selectedResources.includes(line.name)}
                position={index}
            >
                <IndexTable.Cell>
                    <TextStyle variation="strong">{line.item_code}</TextStyle>
                </IndexTable.Cell>
                <IndexTable.Cell>{line.item_description}</IndexTable.Cell>
                <IndexTable.Cell>{line.quantity}</IndexTable.Cell>
                <IndexTable.Cell>{line.price}</IndexTable.Cell>
            </IndexTable.Row>
        ),
    );

    const emptyStateMarkup = (
        <EmptySearchResult
            title={'No lines found'}
            description={'Try changing the filters or search term'}
            withIllustration
        />
    );

    const [selected, setSelected] = useState(0);

    const handleTabChange = useCallback(
        (selectedTabIndex) => setSelected(selectedTabIndex),
        [],
    );

    const documentLinesMarkup = selected == 0 ?
        <SalesOrderLineTable name={name}/> :
        <PalletPlanTable name={name}/>;

    return (

        <>

            <Card>
                <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}></Tabs>
                {documentLinesMarkup}
            </Card>
        </>

    );
}

export default SoLinesList;