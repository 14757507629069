import {Card, IndexTable, TextStyle, useIndexResourceState, Filters, EmptySearchResult} from '@shopify/polaris'
import {useCallback, useState} from "react";

const PoLinesListComponent = ({lines = [], isFetching, showPrices = false}: any) => {

    const [queryValue, setQueryValue] = useState('');
    const handleQueryValueRemove = useCallback(() => setQueryValue('null'), []);
    const handleClearAll = useCallback(() => {
        handleQueryValueRemove();
    }, [handleQueryValueRemove]);

    const filters: any[] = [];
    const resourceName = {
        singular: 'PO line',
        plural: 'PO Lines',
    };

    const {selectedResources, allResourcesSelected, handleSelectionChange} =
        useIndexResourceState(lines);

    const rowMarkup = lines && lines.map(
        (line: any, index: number) => (
            <IndexTable.Row
                id={index.toString()}
                key={index}
                selected={selectedResources.includes(index.toString())}
                position={index}
            >
                <IndexTable.Cell>
                    <TextStyle variation="strong">{line.ItemCode}</TextStyle>
                </IndexTable.Cell>
                <IndexTable.Cell>{line.Dscription}</IndexTable.Cell>
                <IndexTable.Cell>{line.Quantity}</IndexTable.Cell>
                <IndexTable.Cell>{showPrices ? line.Price : 0}</IndexTable.Cell>
                <IndexTable.Cell>{showPrices ? line.LineTotal : 0}</IndexTable.Cell>
            </IndexTable.Row>
        ),
    );

    const emptyStateMarkup = (
        <EmptySearchResult
            title={'No orders lines found'}
            description={'Try changing the filters or search term'}
            withIllustration
        />
    );

    return (
        <Card title="Document Lines">
            <div style={{padding: '16px', display: 'flex'}}>
                <div style={{flex: 1}}>
                    <Filters
                        queryValue={queryValue}
                        filters={filters}
                        onQueryChange={setQueryValue}
                        onQueryClear={handleQueryValueRemove}
                        onClearAll={handleClearAll}
                    />
                </div>
            </div>
            <IndexTable
                resourceName={resourceName}
                itemCount={lines.length}
                loading={isFetching}
                selectedItemsCount={
                    allResourcesSelected ? 'All' : selectedResources.length
                }
                onSelectionChange={handleSelectionChange}
                emptyState={emptyStateMarkup}
                headings={[
                    {title: 'Name'},
                    {title: 'Description'},
                    {title: 'Quantity'},
                    {title: 'Rate'},
                    {title: 'Line Total'},
                ]}
            >
                {rowMarkup}
            </IndexTable>
        </Card>
    );
}

export default PoLinesListComponent