import React, {useCallback, useEffect, useMemo, useReducer, useState} from "react";
import {Combobox, FormLayout, Icon, Listbox, Modal, TextField} from "@shopify/polaris";
import {SearchMinor} from "@shopify/polaris-icons";
import useSafeQueryHook from "../../../../hooks/safe-query/safe-query.hook";
import {useGetQuery} from "../../../../../services/erp-resource-api.service";

const PalletPlanModalComponent = ({model, visible, onSave, onModelCLose}: any) => {

    const {data, isFetching} = useSafeQueryHook(useGetQuery, {
        doc: 'SC Sales Order Line',
        name: `?fields=["*"]&filters=[["SC Sales Order Line", "sc_sales_order", "=", "${model.sc_sales_order}"]]&limit_page_length=10000`
    })

    // {value: 'rustic', label: 'Rustic'},
    const deselectedOptions = useMemo(
        () => {
            return data ?
                data.data.map((line: any) => ({value: line.item_code, label: line.item_description}))
                : []
        },
        [data],
    );

    const [modalActive, setModalActive] = useState(visible);
    const [selectedOption, setSelectedOption] = useState();
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState(deselectedOptions);
    const [form, setForm] = useReducer(
        (state: any, value: any) => ({...state, ...value}),
        {}
    );

    useEffect(() => {
        setModalActive(visible)
    }, [visible])

    useEffect(() => {
        setOptions(deselectedOptions)
    }, [deselectedOptions])

    const handleInputChange = useCallback(
        (value, id) => {
            setForm({[id]: value});
        },
        []
    );


    const formGroup = {
        quantity: {key: 'quantity', label: 'Quantity', type: 'text'},
    }

    const toggleModalActive = useCallback(
        () => {
            setModalActive((modalActive: boolean) => !modalActive)
            onModelCLose()
        },
        [],
    );

    const updateText = useCallback(
        (value) => {
            setInputValue(value);

            if (value === '') {
                setOptions(deselectedOptions);
                return;
            }

            const filterRegex = new RegExp(value, 'i');
            const resultOptions = deselectedOptions.filter((option: any) =>
                option.label.match(filterRegex),
            );
            setOptions(resultOptions);
        },
        [deselectedOptions],
    );

    const updateSelection = useCallback(
        (selected) => {
            const matchedOption = options.find((option: any) => {
                return option.value.match(selected);
            });

            setSelectedOption(selected);
            setInputValue((matchedOption && matchedOption.value) || '');
        },
        [options],
    );

    const optionsMarkup =
        options.length > 0
            ? options.map((option: any) => {
                const {label, value} = option;

                return (
                    <Listbox.Option
                        key={`${value}`}
                        value={value}
                        selected={selectedOption === value}
                        accessibilityLabel={label}
                    >
                        {label}
                    </Listbox.Option>
                );
            })
            : null;

    return <>
        <Modal
            open={modalActive}
            onClose={toggleModalActive}
            title="Pallet Plan Line"
            primaryAction={{
                content: 'Save',
                onAction: () => {
                    const model = data.data.find((line: any) => line.item_code == inputValue)
                    onSave({...form, item_code: inputValue, description: model.item_description})
                    toggleModalActive()
                },
            }}
        >
            <Modal.Section>
                <FormLayout>
                    <Combobox
                        activator={
                            <Combobox.TextField
                                prefix={<Icon source={SearchMinor}/>}
                                autoComplete={'off'}
                                onChange={updateText}
                                label="Item"
                                value={inputValue}
                                placeholder="Search item here"
                            />
                        }
                    >
                        {options.length > 0 ? (
                            <Listbox onSelect={updateSelection}>{optionsMarkup}</Listbox>
                        ) : null}
                    </Combobox>
                    <TextField
                        value={form[formGroup.quantity.key]}
                        onChange={handleInputChange}
                        id={formGroup.quantity.key}
                        label={formGroup.quantity.label}
                        type={'text'}
                        autoComplete={'off'}
                    />
                </FormLayout>
            </Modal.Section>
        </Modal>
    </>
}

export default PalletPlanModalComponent