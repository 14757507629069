import {Badge, Card, Layout, Stack} from "@shopify/polaris";
import {useGetQuery} from "../../../services/erp-resource-api.service";
import useSafeQueryHook from "../../hooks/safe-query/safe-query.hook";

const DocStatusComponent = ({docType, docNum, onValueChange}: any) => {

    const {data} = useSafeQueryHook(useGetQuery, {
        doc: docType,
        name: `?filters=[["${docType}", "doc_num", "=", "${docNum}"]]`
    })

    onValueChange(true)

    if (data) {
        onValueChange(data.data.length == 1)

        return <>
            <Card title={'Status'} sectioned>
                {data.data.length == 1 ? <Badge status={'success'}>Sent to supplier</Badge> :
                    <Badge>Draft</Badge>}
            </Card>
        </>
    }

    return <></>
}

export default DocStatusComponent