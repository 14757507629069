import {FormLayout, Modal, TextField} from "@shopify/polaris";
import React, {useCallback, useEffect, useReducer, useState} from "react";

const SoModelComponent = ({visible, onSave, onModelCLose}: any) => {
    const [modalActive, setModalActive] = useState(visible);
    const [form, setForm] = useReducer(
        (state: any, value: any) => ({...state, ...value}),
        {}
    );

    useEffect(()=>{
        setModalActive(visible)
    },[visible])

    const handleInputChange = useCallback(
        (value, id) => {
            setForm({[id]: value});
        },
        []
    );

    const formGroup = {
        batch_number: {key: 'batch_number', label: 'Batch Number', type: 'text'},
        best_before: {key: 'best_before', label: 'Best Before', type: 'text'},
        quantity: {key: 'quantity', label: 'Quantity', type: 'text'},
    }

    const toggleModalActive = useCallback(
        () => {
            setModalActive((modalActive: boolean) => !modalActive)
            onModelCLose()
        },
        [],
    );

    return <>
        <Modal
            open={modalActive}
            onClose={toggleModalActive}
            title="MC Line"
            primaryAction={{
                content: 'Save',
                onAction: () => {
                    onSave(form)
                    toggleModalActive()
                },
            }}
        >
            <Modal.Section>
                <FormLayout>
                    <TextField
                        value={form[formGroup.batch_number.key]}
                        onChange={handleInputChange}
                        id={formGroup.batch_number.key}
                        label={formGroup.batch_number.label}
                        type={'text'}
                        autoComplete=""
                    />
                    <TextField
                        value={form[formGroup.best_before.key]}
                        onChange={handleInputChange}
                        id={formGroup.best_before.key}
                        label={formGroup.best_before.label}
                        type={'date'}
                        autoComplete=""
                    />
                    <TextField
                        value={form[formGroup.quantity.key]}
                        onChange={handleInputChange}
                        id={formGroup.quantity.key}
                        label={formGroup.quantity.label}
                        type={'number'}
                        autoComplete=""
                    />
                </FormLayout>
            </Modal.Section>
        </Modal>
    </>
}

export default SoModelComponent