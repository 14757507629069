import React, {useCallback, useState} from "react";
import {
    Card,
    EmptySearchResult,
    Filters,
    FormLayout,
    IndexTable,
    Modal, TextField,
    TextStyle,
    useIndexResourceState
} from "@shopify/polaris";
import SoModelComponent from "./so-model.component";
import PopoverCellComponent from "./popover-cell.component";

const SoViewLineListComponent = ({lines, onChangeValue}: any) => {

    const [list, setList] = useState(lines)
    const [visible, setVisible] = useState(false)

    const resourceName = {
        singular: 'PO line',
        plural: 'PO Lines',
    };

    const handleOnClickIndexTableLine = (selectionType: any, toggleType: any, selection: any) => {
        try {
            if (selectionType == 'single') {
                console.log(selection)
            }

            handleSelectionChange(selectionType, toggleType, selection);
        } catch (ex) {
            console.log(ex)
        }
    }

    const {selectedResources, allResourcesSelected, handleSelectionChange} =
        useIndexResourceState(list);

    const rowMarkup = list && list.map(
        (line: any, index: number) => (
            <IndexTable.Row
                id={line.name}
                key={index}
                selected={selectedResources.includes(line.name)}
                position={index}
            >
                <IndexTable.Cell>
                    <TextStyle variation="strong">{line.batch_number}</TextStyle>
                </IndexTable.Cell>
                <IndexTable.Cell>{line.best_before}</IndexTable.Cell>
                <IndexTable.Cell>{line.quantity}</IndexTable.Cell>
                <IndexTable.Cell><PopoverCellComponent /></IndexTable.Cell>
            </IndexTable.Row>
        ),
    );

    const emptyStateMarkup = (
        <EmptySearchResult
            title={'No lines found'}
            description={'Try changing the filters or search term'}
            withIllustration
        />
    );

    return (
        <Card title="Document Lines" actions={[{
            content: 'Add Batch & Best before', onAction() {
                setVisible(true)
            }
        }]}>
            <IndexTable
                resourceName={resourceName}
                itemCount={list ? list.length : 0}
                selectedItemsCount={
                    allResourcesSelected ? 'All' : selectedResources.length
                }
                selectable={false}
                onSelectionChange={handleOnClickIndexTableLine}
                emptyState={emptyStateMarkup}
                headings={[
                    {title: 'Batch Number'},
                    {title: 'Best Before'},
                    {title: 'Quantity'},
                    {title: 'Actions'},
                ]}
            >
                {rowMarkup}
            </IndexTable>
            <SoModelComponent onSave={(form: any) => {
                console.log(form)
                setList([...list, form])
                onChangeValue(form)
            }}
                              onModelCLose={() => {
                                  setVisible(false)
                              }}
                              visible={visible}
            />
        </Card>
    );
}

export default SoViewLineListComponent