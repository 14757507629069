import useHeightAutoFit from "../../hooks/hright-auto-fit/height-auto-fit.hook";
import {useEffect} from "react";

const AutoFitContainer = ({children}: any) => {

    const [ref, setHeightAutoFit] = useHeightAutoFit()

    useEffect(() => {
        setHeightAutoFit()
    })

    return(
        <div ref={ref} className="overflow-auto" style={{overflowX: "hidden"}}>
            {children}
        </div>
    )
}

export default AutoFitContainer