import {Button, Card} from '@shopify/polaris'

const PosCartActionsComponent = () => {
    return (
        <Card>
            <Button size={"large"} fullWidth primary>Checkout</Button>
        </Card>
    )
}

export default PosCartActionsComponent