import {
    EmptySearchResult,
    Filters,
    IndexTable,
    ButtonGroup,
    Scrollable,
    Button,
    TextStyle,
    useIndexResourceState,
    Card,
    Popover,
    ActionList
} from "@shopify/polaris";
import useSafeQueryHook from "../../../../../hooks/safe-query/safe-query.hook";
import {useCreateMutation, useGetQuery} from "../../../../../../services/erp-resource-api.service";
import {useCallback, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {showToast} from "../../../../../../store/reducers/toast.store";

const PalletPlanTable = ({name}: any) => {

    const {data, isFetching} = useSafeQueryHook(useGetQuery, {
        doc: 'SC Pallet Plan',
        name: `?fields=["*"]&filters=[["SC Pallet Plan", "sc_sales_order", "=", "${name}"]]&limit_page_length=10000`
    })

    const [queryValue, setQueryValue] = useState('');
    const handleQueryValueRemove = useCallback(() => setQueryValue('null'), []);
    const handleClearAll = useCallback(() => {
        handleQueryValueRemove();
    }, [handleQueryValueRemove]);
    const navigate = useNavigate()

    const filters: any[] = [];
    const resourceName = {
        singular: 'Pallet Plan',
        plural: 'Pallet Plans',
    };

    const promotedBulkActions = [
        {
            content: 'Edit customers',
            onAction: () => console.log('Todo: implement bulk edit'),
        },
    ];

    // panelID: 'repeat-customers-content-1',

    const handleOnClickIndexTableLine = (selectionType: any, toggleType: any, selection: any) => {
        try {
            if (selectionType == 'single') {
                navigate(`/app/pallet-plan/${selection}`)
            }

            handleSelectionChange(selectionType, toggleType, selection);
        } catch (ex) {
            console.log(ex)
        }
    }

    const {selectedResources, allResourcesSelected, handleSelectionChange} =
        useIndexResourceState(data ? data.data : []);

    const rowMarkup = data && data.data
        .filter((line: any) =>
            line.name.toLowerCase().includes(queryValue.toLowerCase()) ||
            line.type.toLowerCase().includes(queryValue.toLowerCase())
        )
        .map(
            (line: any, index: number) => (
                <IndexTable.Row
                    id={line.name}
                    key={index}
                    selected={selectedResources.includes(line.name)}
                    position={index}
                >
                    <IndexTable.Cell>
                        <TextStyle variation="strong">{line.name}</TextStyle>
                    </IndexTable.Cell>
                    <IndexTable.Cell>{line.type}</IndexTable.Cell>
                </IndexTable.Row>
            ),
        );

    const emptyStateMarkup = (
        <EmptySearchResult
            title={'No lines found'}
            description={'Try changing the filters or search term'}
            withIllustration
        />
    );

    return (
        <Scrollable shadow style={{height: '420px'}}>

            <div style={{padding: '16px', display: 'flex'}}>
                <div style={{flex: 1}}>
                    <Filters
                        queryValue={queryValue}
                        filters={filters}
                        onQueryChange={setQueryValue}
                        onQueryClear={handleQueryValueRemove}
                        onClearAll={handleClearAll}
                    />

                </div>

                <div style={{flex: 1, marginLeft: '1rem'}}>
                    <ButtonGroup>
                        <AddNewPallet/>
                    </ButtonGroup>
                </div>
            </div>
            <IndexTable
                resourceName={resourceName}
                itemCount={data ? data.data.length : 0}
                selectedItemsCount={
                    allResourcesSelected ? 'All' : selectedResources.length
                }
                loading={isFetching}
                onSelectionChange={handleOnClickIndexTableLine}
                emptyState={emptyStateMarkup}
                headings={[
                    {title: 'Pallet Code'},
                    {title: 'Full/Partial'}
                ]}
            >
                {rowMarkup}
            </IndexTable>
        </Scrollable>
    )
}


const AddNewPallet = () => {

    const {name} = useParams()
    const dispatch = useDispatch()

    const [popoverActive, setPopoverActive] = useState(false);
    const [creat] = useCreateMutation()

    const togglePopoverActive = useCallback(
        () => setPopoverActive((popoverActive) => !popoverActive),
        [],
    );

    const activator = (
        <Button onClick={togglePopoverActive} disclosure>
            Add New Pallet
        </Button>
    );

    const addFull = async () => {
        dispatch(showToast({content: 'Creating'}))
        try {
            await creat({method: 'SC Pallet Plan', data: {type: 'Full', sc_sales_order: name}}).unwrap()
        } catch (e) {
            console.log(e)
        }
        window.location.reload();
    }

    const addPartial = async () => {
        dispatch(showToast({content: 'Creating'}))
        try {
            await creat({method: 'SC Pallet Plan', data: {type: 'Partial', sc_sales_order: name}}).unwrap()
        } catch (e) {
            console.log(e)
        }
        window.location.reload();
    }

    return <>
        <Popover
            active={popoverActive}
            activator={activator}
            onClose={togglePopoverActive}
        >
            <ActionList items={[
                {
                    content: 'Full', onAction() {
                        addFull()
                    }
                },
                {
                    content: 'Partial', onAction() {
                        addPartial()
                    }
                }]}/>
        </Popover>
    </>
}

export default PalletPlanTable