import React, {useCallback, useState} from "react";
import {Avatar, Tabs, Page, Card} from "@shopify/polaris";
import ListIndexTableComponent from "../../../../common/list-index-table/list-index-table.component";

const ListPurchaseOrderPage = () => {

    const [selected, setSelected] = useState(0);

    const handleTabChange = useCallback(
        (selectedTabIndex) => setSelected(selectedTabIndex),
        [],
    );

    const tabs = [
        {
            id: '1f1f718f00',
            content: 'All',
            accessibilityLabel: 'All customers',
            panelID: 'all-customers-content-1',
        },
        {
            id: 'accepts-marketing-1',
            content: 'Drafts',
            panelID: 'accepts-marketing-content-1',
        },
        {
            id: 'repeat-customers-1',
            content: 'Confirmed',
            panelID: 'repeat-customers-content-1',
        },
        {
            id: 'prospects-1',
            content: 'Packed',
            panelID: 'prospects-content-1',
        },
        {
            id: 'prospects-2',
            content: 'Completed',
            panelID: 'prospects-content-1',
        },
    ];

    const pageContent = (
        <ListIndexTableComponent navBase={'purchase-order'} query={tabs[selected].id}></ListIndexTableComponent>
    )

    return (
        <Page
            fullWidth={true}
            title={'Purchase Orders'}
            secondaryActions={[
                {
                    content: 'Export',
                    accessibilityLabel: 'Export',
                    onAction: () => alert('Export action'),
                }
            ]}
            // additionalNavigation={<Avatar size="small" initials="CD" customer={false}/>}
        >
            <Card>
                <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}></Tabs>
                {pageContent}
            </Card>
        </Page>
    )
}

export default ListPurchaseOrderPage