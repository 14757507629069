import {Card, Layout, MediaCard, Page} from '@shopify/polaris'
import PosCartComponent from "../components/pos-cart/pos-cart.component";
import PosCartTableComponent from "../components/pos-cart-table/pos-cart-table.component";
import ItemSelectorModel from "../components/item-selector-model/item-selector.model";
import PosCustomerComponent from "../components/pos-customer/pos-customer.component";
import PosNotesComponent from "../components/pos-notes/pos-notes.component";
import AutoFitContainer from "../../../common/auto-fit-container/auto-fit-container.component";

const PosHomePage = () => {

    return (
        <Page fullWidth={true}>
            <Layout>
                <Layout.Section>
                    <Layout>
                        <Layout.Section secondary>
                            <MediaCard
                                title="Getting Started"
                                primaryAction={{
                                    content: 'Learn about getting started',
                                    onAction: () => {
                                    },
                                }}
                                description="Discover how Shopify can power up your entrepreneurial journey."
                                popoverActions={[{
                                    content: 'Dismiss', onAction: () => {
                                    }
                                }]}
                            >
                                <img
                                    alt=""
                                    width="100%"
                                    height="100%"
                                    style={{
                                        objectFit: 'cover',
                                        objectPosition: 'center',
                                    }}
                                    src="https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850"
                                />
                            </MediaCard>
                            <PosNotesComponent/>
                            <Card title="Tags" sectioned>
                                <p>Add tags to your order.</p>
                            </Card>
                            <Card title="Discounts or loyalty points" sectioned>
                                <AutoFitContainer>
                                    <MediaCard
                                        title="Getting Started"
                                        primaryAction={{
                                            content: 'Learn about getting started',
                                            onAction: () => {
                                            },
                                        }}
                                        description="Discover how Shopify can power up your entrepreneurial journey."
                                        popoverActions={[{
                                            content: 'Dismiss', onAction: () => {
                                            }
                                        }]}
                                    >
                                        <img
                                            alt=""
                                            width="100%"
                                            height="100%"
                                            style={{
                                                objectFit: 'cover',
                                                objectPosition: 'center',
                                            }}
                                            src="https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850"
                                        />
                                    </MediaCard>
                                </AutoFitContainer>
                            </Card>
                        </Layout.Section>
                        <Layout.Section>
                            <PosCartTableComponent type={'index-table'}></PosCartTableComponent>
                        </Layout.Section>

                    </Layout>
                </Layout.Section>
                <Layout.Section secondary>
                    <PosCustomerComponent/>
                    <PosCartComponent/>
                </Layout.Section>
            </Layout>

            <ItemSelectorModel></ItemSelectorModel>
        </Page>
    )
}

export default PosHomePage