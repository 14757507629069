import {useNavigate} from "react-router-dom";
import safeQueryHook from "../../../hooks/safe-query/safe-query.hook";
import {useExecuteQueryQuery} from "../../../../services/erp-method-api.service";
import {IndexTable, useIndexResourceState} from "@shopify/polaris";

const QueryIndexTable = ({tableConfig}: any) => {
    const navigate = useNavigate()
    const {data, isFetching} = safeQueryHook(useExecuteQueryQuery, {data: {name: tableConfig.query}})
    const resourceName = {
        singular: 'purchase order',
        plural: 'purchase orders',
    };

    const {
        selectedResources,
        allResourcesSelected,
        handleSelectionChange
    } = useIndexResourceState(data ? data.message : []);

    const handleOnClickIndexTableLine = (selectionType: any, toggleType: any, selection: any) => {
        try {
            if (selectionType == 'single') {
                navigate(`/app/purchase-order/${selection}`)
            }

            handleSelectionChange(selectionType, toggleType, selection);
        } catch (ex) {
            console.log(ex)
        }
    }

    const rowMarkup = data && data.message.map(
        (line: any, index: any) => (
            <IndexTable.Row
                id={line.DocEntry}
                key={index}
                selected={selectedResources.includes(line.DocEntry)}
                position={index}
            >
                {tableConfig.header_fields.map((_line: any) => (
                    <IndexTable.Cell key={`${index}-${_line.key}`}>{line[_line.key]}</IndexTable.Cell>))}
            </IndexTable.Row>
        ),
    );

    // const headers = tableConfig && tableConfig.header_fields.map((line: any, index: number) => <h1 key={index}>{line}</h1>)

    return (
        <>
            <IndexTable
                resourceName={resourceName}
                itemCount={data ? data.message.length : 0}
                selectedItemsCount={
                    allResourcesSelected ? 'All' : selectedResources.length
                }
                onSelectionChange={handleOnClickIndexTableLine}
                loading={isFetching}
                selectable={true}
                headings={tableConfig.header_fields.map((line: any) => ({title: line.label}))}
            >
                {rowMarkup}
            </IndexTable>
        </>
    );
}

export default QueryIndexTable