import {Avatar, TextStyle, Card, Layout, Page, Badge} from "@shopify/polaris";
import React, {useState} from "react";
import PoLinesListComponent from "../component/po-lines-list/po-lines-list.component";
import useSafeQueryHook from "../../../../hooks/safe-query/safe-query.hook";
import {useExecuteQueryQuery} from "../../../../../services/erp-method-api.service";
import DataTuple from "../../../../common/data-tuple/data-tuple.component";
import {useParams} from "react-router-dom";
import Moment from "moment";
import {useCreateMutation} from "../../../../../services/erp-resource-api.service";
import DocStatusComponent from "../../../../common/doc-status/doc-status.component";

const ViewPurchaseOrderPage = () => {

    const [showPrices, setShowPrices] = useState(false)
    const {name} = useParams()
    const {data, isFetching} = useSafeQueryHook(useExecuteQueryQuery, {data: {name: 'a156a84592', id: name}})
    const [primaryActionLoading, setPrimaryActionLoading] = useState(false)
    const [primaryActionDisabled, setPrimaryActionDisabled] = useState(false)
    const [addToList] = useCreateMutation()

    const model = data ? data.message : {}

    const dateFormatter = (data: any) => {
        return Moment(data).format('DD-MM-YYYY')
    }

    const sendToSupplier = async () => {
        try {
            setPrimaryActionLoading(true)
            setPrimaryActionDisabled(true)
            let result = await addToList({
                method: `SC Sales Order`,
                data: {doc_num: model.DocNum, doc_entry: model.DocEntry}
            }).unwrap()

            model.Lines.map(async (line: any) => {
                await addToList({
                    method: 'SC Sales Order Line',
                    data: {
                        sc_sales_order: result.data.name,
                        doc_entry: model.DocEntry,
                        line_number: line.LineNum,
                        item_code: line.ItemCode,
                        item_description: line.Dscription,
                        quantity: line.Quantity,
                        price: line.Price,
                        currency: line.Currency,
                    }
                })
            })
        } catch (e) {
            console.log(e)
            setPrimaryActionDisabled(false)
        } finally {
            setPrimaryActionLoading(false)
        }
    }

    const pageContent = (
        <Layout>
            <Layout.Section>
                <Card title="Purchase order header" sectioned>
                    <Layout>
                        <Layout.Section>
                            <Layout>
                                <Layout.Section oneThird>
                                    <DataTuple title={'PO Number'} value={model.DocNum}/>
                                    <DataTuple title={'PO Created Date'} value={dateFormatter(model.DocDate)}/>
                                    <DataTuple title={'PO Due Date'} value={dateFormatter(model.DocDueDate)}/>
                                    <DataTuple title={'Shipping Instructions'} value={model.Comments}/>
                                </Layout.Section>
                                <Layout.Section oneThird>
                                    <DataTuple title={'PO Status'} value={'PO-0001'}/>
                                </Layout.Section>
                            </Layout>
                        </Layout.Section>
                    </Layout>
                </Card>

                <PoLinesListComponent showPrices={showPrices} isFetching={isFetching} lines={model.Lines}/>

            </Layout.Section>

            <Layout.Section secondary>
                <Card title="Document information" sectioned>
                    <Layout>
                        <Layout.Section>
                            <Layout>
                                <Layout.Section oneThird>
                                    <DataTuple title={'Status'} value={model.CANCELED}/>

                                </Layout.Section>
                            </Layout>
                        </Layout.Section>
                    </Layout>
                </Card>
                <DocStatusComponent docType={'SC Sales Order'} docNum={model.DocNum} onValueChange={(value: any) => {
                    setPrimaryActionDisabled(value)
                }}/>
            </Layout.Section>
        </Layout>
    )

    return (
        <Page
            fullWidth={true}
            title={'Purchase Orders'}
            titleMetadata={<Badge status="attention">{model.submitted}</Badge>}
            subtitle={'#23424'}
            primaryAction={{
                content: 'Send to supplier',
                loading: primaryActionLoading,
                disabled: primaryActionDisabled,
                accessibilityLabel: 'Secondary action label',
                onAction: sendToSupplier,
            }}
            secondaryActions={[]}
            breadcrumbs={[{content: 'Products', url: '/app/purchase-order'}]}
        >
            {pageContent}
        </Page>
    )
}

export default ViewPurchaseOrderPage