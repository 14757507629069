import {ActionList, Button, Popover } from "@shopify/polaris";
import {useCallback, useState} from "react";
import {DeleteMajor, EditMajor} from "@shopify/polaris-icons";

const PopoverCellComponent = () => {
    const [popoverActive, setPopoverActive] = useState(false);

    const togglePopoverActive = useCallback(
        () => setPopoverActive((popoverActive) => !popoverActive),
        [],
    );

    const activator = (
        <Button onClick={togglePopoverActive} plain disclosure>
            More actions
        </Button>
    );

    return (
            <Popover
                active={popoverActive}
                activator={activator}
                onClose={togglePopoverActive}
            >
                <ActionList items={[{content: 'Remove', icon: DeleteMajor}, {content: 'Edit', icon: EditMajor}]} />
            </Popover>
    );
}

export default PopoverCellComponent